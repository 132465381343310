import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "login-form-container" }
const _hoisted_2 = { class: "login-form-content" }
const _hoisted_3 = { class: "form" }
const _hoisted_4 = { class: "ar-field" }
const _hoisted_5 = { class: "formlabel" }
const _hoisted_6 = ["placeholder"]
const _hoisted_7 = {
  key: 0,
  class: "ar-error"
}
const _hoisted_8 = {
  key: 1,
  class: "ar-error"
}
const _hoisted_9 = { class: "ar-field" }
const _hoisted_10 = { class: "formlabel" }
const _hoisted_11 = {
  key: 0,
  class: "ar-error"
}
const _hoisted_12 = {
  key: 1,
  class: "ar-error"
}
const _hoisted_13 = { class: "field-small-action" }
const _hoisted_14 = { class: "switch-form" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FixedHeaderTop = _resolveComponent("FixedHeaderTop")!
  const _component_PageHeader = _resolveComponent("PageHeader")!
  const _component_PasswordInput = _resolveComponent("PasswordInput")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Snackbar = _resolveComponent("Snackbar")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_FixedHeaderTop, { maxWidth: 48 }),
      _createVNode(_component_PageHeader, { defaultHeader: true }, {
        default: _withCtx(() => [
          _createElementVNode("h1", null, _toDisplayString(_ctx.$t('header.login')), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_FixedHeaderTop, { maxWidth: 48 }),
      _createElementVNode("form", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("label", _hoisted_5, _toDisplayString(_ctx.$t('form.username')), 1),
          _withDirectives(_createElementVNode("input", {
            name: "email",
            type: "email",
            placeholder: _ctx.$t('form.username'),
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.form.username) = $event)),
            autocomplete: "off",
            tabindex: "0"
          }, null, 8, _hoisted_6), [
            [_vModelText, _ctx.form.username]
          ]),
          (_ctx.errorClass('email', _ctx.form.username)=='required')
            ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(_ctx.$t('error.required')), 1))
            : _createCommentVNode("", true),
          (_ctx.errorClass('email', _ctx.form.username)=='wrongMail')
            ? (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(_ctx.$t('error.wrongMail')), 1))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_9, [
          _createElementVNode("label", _hoisted_10, _toDisplayString(_ctx.$t('form.password')), 1),
          _createVNode(_component_PasswordInput, {
            placeholder: _ctx.$t('form.password'),
            onOnChange: _cache[1] || (_cache[1] = (value) => _ctx.form.password = value),
            tabindex: 0
          }, null, 8, ["placeholder"]),
          (_ctx.errorClass('password', _ctx.form.password)=='required')
            ? (_openBlock(), _createElementBlock("span", _hoisted_11, _toDisplayString(_ctx.$t('error.required')), 1))
            : (_ctx.errorClass('password', _ctx.form.password)=='toShort')
              ? (_openBlock(), _createElementBlock("span", _hoisted_12, _toDisplayString(_ctx.$t('error.pw_too_short')), 1))
              : _createCommentVNode("", true),
          _createElementVNode("small", _hoisted_13, [
            _createVNode(_component_router_link, {
              to: "/forgotpassword",
              tabindex: "-1"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('pw_forgotten')) + "?", 1)
              ]),
              _: 1
            })
          ])
        ]),
        _createVNode(_component_Button, {
          text: _ctx.$t('button.login'),
          waitForResponse: _ctx.waitForResponse,
          onHandleClicked: _ctx.submitForm,
          tabindex: 0
        }, null, 8, ["text", "waitForResponse", "onHandleClicked"]),
        _createElementVNode("div", _hoisted_14, [
          _createElementVNode("p", null, _toDisplayString(_ctx.$t('not_registered_yet')) + "?", 1),
          _createVNode(_component_router_link, {
            to: "/registerform",
            class: "link"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('register_now')), 1)
            ]),
            _: 1
          })
        ])
      ])
    ]),
    _createVNode(_component_Snackbar, {
      show: _ctx.snackbar.show,
      text: _ctx.snackbar.text,
      error: _ctx.snackbar.error
    }, null, 8, ["show", "text", "error"])
  ]))
}